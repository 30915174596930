import classNames from "classnames";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import BackToOverview from "../../components/BackToOverview";
import PartnerCard from "../../components/cards/PartnerCard";
import DownloadTeaser from "../../components/DownloadTeaserBig";
import { useGlobalState } from "../../state/globalState";
import { IFilterItem, IPartner } from "../../types/gatsby";

export interface PartnersProps {
  data: {
    partnerData: {
      edges: {
        node: IPartner;
      }[];
    };
    filterData: {
      edges: {
        node: IFilterItem;
      }[];
    };
  };
}

const Partners: React.FC<PartnersProps> = ({
  data: { partnerData, filterData },
}) => {
  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: false,
    });
  }, []);

  const { t } = useTranslation(["partners"]);

  const [partnerCategoryFilter, setPartnerCategoryFilter] =
    React.useState<string>("all");

  const filteredPartnerData = React.useMemo(() => {
    const selectedFilter = filterData.edges.find(
      ({ node: filter }) => filter.id === partnerCategoryFilter
    );
    const itemIds =
      selectedFilter !== undefined
        ? selectedFilter.node.itemIds
        : filterData.edges.flatMap(({ node: filter }) => filter.itemIds);
    return partnerData.edges.filter(({ node: p }) =>
      itemIds.includes(p.alternative_id)
    );
  }, [partnerCategoryFilter]);

  const handleCategoryFilterClick = React.useCallback((id: string) => {
    setPartnerCategoryFilter(id);
  }, []);

  return (
    <div>
      <section className="flex flex-col justify-center pt-32 mx-auto container-4xl">
        <div className="z-10">
          <h1 className="text-5xl md:text-6xl lg:text-6.5xl leading-none text-left text-gray-500 font-black uppercase">
            <Trans i18nKey="partners:title_sub_partners">
              Unsere{" "}
              <span className="inline-block max-w-full font-black text-purple-500 break-words with-hyphens">
                nachhaltigen
              </span>
              <span className="inline-block max-w-full font-black break-words text-amber-500 with-hyphens">
                Partner.
              </span>
            </Trans>
          </h1>
        </div>
      </section>

      <div className="px-4 pb-16 container-4xl">
        <div className="prose-lg text-gray-500">
          <div>
            <p>{t("partners:description_sub_partners")}</p>
          </div>
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="pt-12 container-7xl">
          <BackToOverview
            to="/our-partners"
            cta={t("partners:cta_show_general_overview")}
          />
        </div>
        <div className="flex pt-12 overflow-x-scroll sm:justify-center sm:flex-wrap sm:overflow-x-auto container-7xl">
          <button
            className={classNames([
              "mb-4 mr-4",
              partnerCategoryFilter === "all"
                ? "button-blue-filled"
                : "button-blue-outline",
            ])}
            onClick={() => {
              handleCategoryFilterClick("all");
            }}
          >
            {t("partners:filter.all")}
          </button>
          {filterData.edges.map(({ node: filter }) => (
            <button
              key={filter.id}
              className={classNames([
                "mb-4 mr-4",
                partnerCategoryFilter === filter.id
                  ? "button-blue-filled"
                  : "button-blue-outline",
              ])}
              onClick={() => {
                handleCategoryFilterClick(filter.id);
              }}
            >
              {filter.title}
            </button>
          ))}
        </div>
        <div className="px-4 py-16 overflow-visible container-7xl">
          <div className="grid grid-cols-6 gap-6">
            {filteredPartnerData.map(({ node: partner }) => (
              <PartnerCard partner={partner} key={partner.id} />
            ))}
          </div>
        </div>
      </div>
      <DownloadTeaser />
    </div>
  );
};

export const query = graphql`
  query PartnersQuery($language: String!) {
    partnerData: allPlan3TPartner(
      filter: { id: { ne: "dummy" } }
      sort: { fields: fields___weight }
    ) {
      edges {
        node {
          id
          name
          coreIndustry
          alternative_id
          tagLine
          logo_localImage {
            id
            childImageSharp {
              gatsbyImageData(width: 72, quality: 90, placeholder: BLURRED)
            }
          }
          coverImage_localImage {
            id
            childImageSharp {
              gatsbyImageData(
                width: 320
                quality: 90
                placeholder: BLURRED
                blurredOptions: { width: 100 }
              )
            }
          }
          fields {
            categories {
              id
              title
            }
          }
        }
      }
    }
    filterData: allPlan3TPartnerCategory(filter: { id: { ne: "dummy" } }) {
      edges {
        node {
          id
          title
          itemIds
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Partners;
